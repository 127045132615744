import * as ContextMenu from '@radix-ui/react-context-menu'
import {Link} from '@remix-run/react'

export interface LogoDropdownProps {
  children: React.ReactNode
  hasActiveSession: boolean
}

export function LogoDropdown({children, hasActiveSession}: LogoDropdownProps) {
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className="border-radix-mauve7 bg-radix-mauve3/70 z-30 rounded-lg border p-1 backdrop-blur-sm">
          <ContextMenu.Item
            asChild
            className="hover:bg-radix-blue8 flex w-48 justify-between rounded-sm border border-transparent px-2 py-1 text-sm leading-none font-medium outline-hidden"
          >
            <Link to={hasActiveSession ? '/home' : '/'}>Home</Link>
          </ContextMenu.Item>
          <ContextMenu.Item
            asChild
            className="hover:bg-radix-blue8 flex w-48 justify-between rounded-sm border border-transparent px-2 py-1 text-sm leading-none font-medium outline-hidden"
          >
            <Link to="/blog">Blog</Link>
          </ContextMenu.Item>
          <ContextMenu.Item
            asChild
            className="hover:bg-radix-blue8 flex w-48 justify-between rounded-sm border border-transparent px-2 py-1 text-sm leading-none font-medium outline-hidden"
          >
            <Link to="/docs">Documentation</Link>
          </ContextMenu.Item>
          <ContextMenu.Separator className="bg-radix-mauve6 m-1 h-px" />
          <ContextMenu.Item
            asChild
            className="hover:bg-radix-blue8 flex w-48 justify-between rounded-sm border border-transparent px-2 py-1 text-sm leading-none font-medium outline-hidden"
          >
            <Link to="/about/brand-assets">Brand assets</Link>
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  )
}
