import type {LinkProps} from '@remix-run/react'
import {Link, useLocation} from '@remix-run/react'
import {cx} from 'class-variance-authority'
import {useEffect, useState} from 'react'
import {useParentLoaderData} from '~/hooks/useParentLoaderData'
import {ThreeBarsIcon} from '../icons'
import {DepotIcon, DepotLogo} from '../logo'
import {LogoDropdown} from './LogoDropdown'
import {MarketingMenu} from './MarketingMenu'

export interface MarketingHeaderProps {
  constrainWidth?: boolean
}

export function MarketingHeader({constrainWidth}: MarketingHeaderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const {pathname} = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  const rootData = useParentLoaderData('root')
  const hasActiveSession = Boolean(rootData?.email)

  return (
    <div className="border-radix-mauve12/10 bg-radix-mauve-black/50 sticky top-0 z-10 flex w-full flex-col items-center justify-center border-b shadow-lg backdrop-blur-sm backdrop-saturate-150">
      <div
        className={cx(
          'flex w-full items-center gap-4 py-4',
          constrainWidth && 'max-w-7xl px-4 sm:px-0',
          !constrainWidth && 'px-8',
        )}
      >
        <div className="flex-1 md:flex-none">
          <Link to={hasActiveSession ? '/home' : '/'} prefetch="intent">
            <LogoDropdown hasActiveSession={hasActiveSession}>
              <DepotIcon className="h-6 md:hidden" />
              <DepotLogo className="hidden h-6 md:block" />
              <span className="sr-only">Depot</span>
            </LogoDropdown>
          </Link>
        </div>

        <div className="hidden flex-1 md:block">
          <MarketingMenu />
        </div>

        <div>
          <AccountMenu />
        </div>

        <button
          className="border-radix-mauve7 flex h-8 items-center gap-2 rounded-sm border p-2 font-medium md:hidden"
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <ThreeBarsIcon /> <span>Menu</span>
        </button>
      </div>

      {isOpen && <MarketingMenuMobile hasActiveSession={hasActiveSession} />}
    </div>
  )
}

function AccountMenu() {
  const rootData = useParentLoaderData('root')
  const hasActiveSession = Boolean(rootData?.email)
  const lastVisitedOrganizationID = rootData?.lastVisitedOrganizationID

  return (
    <div className="flex items-center gap-2">
      {hasActiveSession && (
        <Link
          to={lastVisitedOrganizationID ? `/orgs/${lastVisitedOrganizationID}/projects` : '/orgs'}
          className="border-radix-grass7 bg-radix-grass3 text-radix-grass12 flex h-8 items-center gap-2 rounded-sm border p-2 font-medium"
          prefetch="intent"
        >
          Dashboard
        </Link>
      )}
      {!hasActiveSession && (
        <Link
          to="/sign-in"
          className="border-radix-mauve7 bg-radix-mauve3 text-radix-mauve11 hover:border-radix-mauve8 flex h-8 items-center gap-2 rounded-md border px-3 py-2 text-sm whitespace-nowrap"
          prefetch="intent"
        >
          Sign In
        </Link>
      )}
      {!hasActiveSession && (
        <Link
          to="/sign-up"
          className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 hover:border-radix-grass8 flex h-8 items-center gap-2 rounded-md border px-3 py-2 text-sm whitespace-nowrap"
          prefetch="intent"
        >
          Start for free
        </Link>
      )}
    </div>
  )
}

export function MarketingMenuMobile({hasActiveSession}: {hasActiveSession: boolean}) {
  return (
    <div className="border-radix-mauve12/10 text-radix-mauve11 flex w-full flex-col gap-6 border-t p-4">
      <div className="grid grid-cols-2">
        <HeaderLink to={hasActiveSession ? '/home' : '/'}>Home</HeaderLink>
        <HeaderLink to="/pricing">Pricing</HeaderLink>
      </div>

      <div className="space-y-2">
        <div className="text-radix-mauve12 px-4 text-sm font-medium">Products</div>
        <div className="grid grid-cols-2">
          <HeaderLink to="/products/container-builds">Docker Builds</HeaderLink>
          <HeaderLink to="/products/github-actions">GitHub Actions Runners</HeaderLink>
          <HeaderLink to="/docs/container-builds/reference/api-overview">Build API</HeaderLink>
        </div>
      </div>

      <div className="space-y-2">
        <div className="text-radix-mauve12 px-4 text-sm font-medium">Resources</div>
        <div className="grid grid-cols-2">
          <HeaderLink to="/integrations">Integrations</HeaderLink>
          <HeaderLink to="/blog">Blog</HeaderLink>
          <HeaderLink to="/changelog">Changelog</HeaderLink>
          <HeaderLink to="/docs">Docs</HeaderLink>
          <HeaderLink to="/help">Help</HeaderLink>
          <HeaderLink to="/about">Company</HeaderLink>
        </div>
      </div>

      <div className="space-y-2">
        <div className="text-radix-mauve12 px-4 text-sm font-medium">Tools</div>
        <div className="grid grid-cols-2">
          <HeaderLink to="/integrations">Dockerfile Explorer</HeaderLink>
          <a
            href="https://depot.ai"
            className="hover:bg-radix-mauve3 hover:text-radix-mauve12 rounded-sm px-4 py-2 text-xl md:text-base"
          >
            depot.ai
          </a>
          <HeaderLink to="/github-actions-price-calculator">GitHub Actions Price Calculator</HeaderLink>
        </div>
      </div>
    </div>
  )
}

function HeaderLink({className, children, ...props}: LinkProps) {
  return (
    <Link
      className={cx(
        'hover:bg-radix-mauve3 hover:text-radix-mauve12 rounded-sm px-4 py-2 text-xl md:text-base',
        className,
      )}
      prefetch="intent"
      {...props}
    >
      {children}
    </Link>
  )
}
